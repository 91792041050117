import { FC, Fragment } from "react";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";
import styled, { Size } from "styled";
import { Container, Row, Col } from "reactstrap";

import { Header, MobileMenu, Footer, Title } from "components";
import { CategoriesData } from "views/prijslijst/Data";
import { Category } from "views/prijslijst/Categorie";

const Categories = styled(Container)`
  margin-top: 100px;
  margin-bottom: 100px;
`;
const TitleContainer = styled(Col)`
  margin-top: 100px;
  margin-bottom: 50px;

  h1 {
    font-size: 8rem;
  }

  @media ${Size.lg} {
    h1 {
      font-size: 6rem;
    }
  }
  @media ${Size.md} {
    h1 {
      font-size: 4rem;
    }
  }
  @media ${Size.sm} {
    margin-bottom: 50px;
    h1 {
      text-align: center;
      font-size: 3rem;
    }
  }
  @media ${Size.xs} {
    margin-bottom: 25px;
    h1 {
      font-size: 2.5rem;
    }
  }
  @media ${Size.xxs} {
    margin-bottom: 0;
    h1 {
      font-size: 2rem;
    }
  }
`;
const Line = styled.div`
  width: 5%;
  height: 5px;
  background-color: ${({ theme }): string => theme.gray};
  border-radius: 10px;

  @media ${Size.sm} {
    margin: 0 auto;
  }
`;
const Description = styled(Col)`
  margin-top: -20px;
  margin-bottom: 100px;
  font-size: 1rem;
  width: 85%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media ${Size.sm} {
    margin-top: -60px;
  }
`;
export const PrijsLijst: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Instituut Visage | Bekijk al onze prijzen! </title>
        <meta
          content="Instituut Visage biedt u een waaier van verzorgingen aan. Hieronder vindt u een mooi overzicht van onze arrangementen en de prijs per behandeling"
          name="description"
        />
      </Helmet>
      <MobileMenu />
      <Header />
      <Row>
        <TitleContainer
          lg={{ size: 10, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 12, offset: 0 }}
          xl={{ size: 10, offset: 1 }}
        >
          <Title isH1 text="prijslijst" />
          <Line />
        </TitleContainer>
      </Row>
      <Categories>
        {CategoriesData.map(({ name, subCategories }) => (
          <Category key={uuid()} name={name} subCategories={subCategories} />
        ))}
      </Categories>
      <Description xl={{ size: 10, offset: 2 }}>
        De prijzen kunnen variëren naar gelang de combinaties van behandelingen.
        Prijzen kunnen licht afwijken.
      </Description>
      <Footer />
    </Fragment>
  );
};
