import { FC } from 'react';
import styled, { Size } from 'styled';

/* styled components */
const Char = styled.span<{ isWhite: boolean }>`
  font-size: 20rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme, isWhite }) => isWhite ? 'white' : theme.bg};
  line-height: 0.68;

  @media ${Size.lg}{
    font-size: 15rem;
  }
  @media ${Size.md}{
    font-size: 8rem;
  }
  @media ${Size.sm}{
    font-size: 7rem
  }
  @media ${Size.xs}{
    font-size: 6rem;
  }
`;

interface LetterProps {
  char: string;
  isWhite: boolean;
}

// @ts-ignore
export const Letter: FC<LetterProps> = ({ className, char, isWhite }) => {
  return (
    <Char className={className} isWhite={isWhite}>{char}</Char>
  );
};
