import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';

/* images */
import maneating from 'images/afslanking/maneating.jpg';
/* shared components */
import { StepBlock } from './StepBlock';

/* styled components */
const Wrapper = styled.div`
  position: relative;
  padding: 150px 0;
  margin: 50px 0;
  @media ${Size.md}{
    padding: 100px 0;
    margin: 100px 0;
  }
  @media ${Size.xs}{
    padding: 50px 0;
    margin: 50px 0;
  }
`;
const BgBox = styled.div`
  background-color: ${({ theme }) => theme.main};
  width: 30%;
  height: 100%;
`;
const ImgContainer = styled(Col)`
  background-image: url(${maneating});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  @media ${Size.sm}{
    min-height: 300px;
  }
  @media ${Size.xxs}{
    min-height: 250px;
  }
`;
const TextContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.bg};
  text-align: center;
  padding: 150px 25px;
  @media ${Size.md}{
    padding: 75px 25px;
    p{ width: 75%; }
  }
  @media ${Size.sm}{
    p{ width: 85%; }
  }
`;

export const StepThree: FC = () => {
  return (
    <Wrapper>
      <StepBlock side="left" value="3" />
      <BgBox />
      <Row>
        <ImgContainer lg={{ size: 5, offset: 1 }} xl={{ size: 4, offset: 1 }} />
        <TextContainer lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 2 }} >
          <h3 className="step">
            Leeffase
          </h3>
          <p>
            We willen natuurlijk afslanken... maar we willen vooral ons gewicht behouden!
            Door de ontgifting en stabilisatie hebben we ons lichaam tot rust gebracht en
            hebben we ontdekt wat goed en slecht voor ons eigen lichaam is. Maar genieten
            van al dat lekkers moet ook kunnen. Daarom leren we nu wat we moeten doen
            na een culinair weekendje en hoe we moeten compenseren na een overdaad, zodanig dat
            we ons gewicht mooi stabiel kunnen houden. Genieten en toch ons gewicht behouden.
          </p>
        </TextContainer>
      </Row>
    </Wrapper>
  );
};
