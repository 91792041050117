import { FC } from "react";
import styled, { Size } from "styled";
import { Container, Row, Col } from "reactstrap";
import { Letter } from "../../components/Letter";

const Wrapper = styled.div`
  margin: 150px 0 250px 0;

  @media ${Size.xs} {
    margin: 0 0 250px 0;
  }
`;
const TextContainer = styled(Col)`
  position: relative;
  padding: 160px 40px;
  margin: 0 auto;
  background-color: ${({ theme }): string => theme.bg};
  p {
    margin: 15px 0;
  }
  @media ${Size.sm} {
    padding: 100px 40px;
  }
  @media ${Size.xs} {
    padding: 100px 30px;
  }
  @media ${Size.xxs} {
    padding: 50px 15px 75px 15px;
  }
`;
const AbsoluteLetter = styled(Letter)`
  position: absolute;
  bottom: -12%;
  right: 0;

  @media ${Size.lg} {
    bottom: -6%;
  }
  @media ${Size.md} {
    bottom: -2%;
    right: 15%;
    font-size: 10rem;
  }
`;

export const VoorWie: FC = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <TextContainer
            lg={{ size: 6 }}
            md={{ size: 10 }}
            sm={{ size: 10 }}
            xl={{ size: 6 }}
            xs={{ size: 10 }}
          >
            <h2>Wie kan met een diodelaser behandeld worden?</h2>
            <p>
              In principe alle huidtypes en haarsoorten van zowel vrouwen als
              mannen. Echter zijn er een paar uitzonderingen: de laser reageert
              niet op grijze- of donsharen door het ontbreken van pigment.
            </p>
            <p>
              Voorafgaand nemen we ALTIJD eerst een{" "}
              <strong>intake gesprek</strong> af waar we al de contra-indicaties
              nagaan, uw huidtype bepalen en ook al eens een testshot doen. (dit
              verschil per huidtype van 24u tot 48u voor we echt van start
              gaan).
            </p>
            <AbsoluteLetter char="W" isWhite />
          </TextContainer>
        </Row>
      </Container>
    </Wrapper>
  );
};
