export const Size = {
  lg: '(max-width: 1200px)',
  md: '(max-width: 992px)',
  sm: '(max-width: 768px)',
  xs: '(max-width: 576px)',
  xxs: '(max-width: 450px)'
};
export const MobileFirst = {
  ms: '(min-width: 420px)'
};
