import { FC } from "react";
import styled, { Size } from "styled";
import { Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
/* images */
import img from "images/laserontharing/gun.png";
import _dots from "images/_dots.png";
/* shared components */
import { LetterBlock } from "components";

/* styled components */
const Wrapper = styled.div`
  margin: 150px 0;
  width: 100%;
  position: relative;

  @media ${Size.sm} {
    margin: 100px 0;
  }
  @media ${Size.xs} {
    margin: 75px 0;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 200px;
  margin-bottom: 25px;
  h2 {
    font-weight: 800;
    color: ${({ theme }) => theme.gray};
    z-index: 1;
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  @media ${Size.lg} {
    min-height: 135px;
  }
  @media ${Size.md} {
    min-height: 100px;
    h2 {
      text-align: center;
      margin-bottom: 0;
    }
  }
  @media ${Size.xs} {
    h2 {
      margin-top: 50px;
    }
  }
`;

const TextContainer = styled(Col)`
  h2 {
    margin-bottom: 35px;
  }
  p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  ul {
    margin-top: 30px;
    margin-left: 15px;
    li {
      list-style-type: disc;
      p {
        font-weight: 500;
      }
    }
  }
  @media ${Size.md} {
    text-align: center;

    ul {
      margin-bottom: 100px;
      li {
        list-style-type: none;
        p {
          font-weight: bolder;
        }
      }
    }
  }
`;
const ImgContainer = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  div {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      right: -107.5%;
      top: -20px;
      left: 0;
      margin: 0 auto;
      background-color: white;
      background-image: url(${_dots});
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
  @media ${Size.md} {
    margin-top: 3rem;
  }
`;
export const HoeWerktHet: FC = () => {
  return (
    <Wrapper>
      <TitleContainer>
        <LetterBlock char="H" onRight={false} />
        <Col
          lg={{ size: 10, offset: 2 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 10, offset: 2 }}
          xs={{ size: 10, offset: 1 }}
        >
          <Fade left>
            <h2>Hoe werkt het?</h2>
          </Fade>
        </Col>
      </TitleContainer>
      <Row>
        <TextContainer
          lg={{ size: 4, offset: 2 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 2 }}
          xs={{ size: 10, offset: 1 }}
        >
          <Fade bottom>
            <p>
              De gekoelde kop van het laserapparaat wordt op het te behandelen
              gebied geplaatst.
            </p>
            <p>
              De energie die vrijkomt uit de diodelaser wordt door het pigment
              dat zich onderaan in de haarfollikel bevindt geabsorbeerd. De
              laser geeft zijn energie in een fractie van een seconde vrij
              waardoor de haarfollikel wordt vernietigd, geen voedingsstoffen
              meer krijgt en het haar na ca. 2 weken voorgoed verdwenen is.
            </p>
            <p>
              Door het gebruik van de uiterst gekoelde kop én de korte
              impulstijd, blijft de huid en de omgeving van het haar goed
              beschermt. Het laserlicht heeft een golflengte van 800nm, dit is
              de ideale golflengte dat door het pigment van het haar opgenomen
              wordt.
            </p>
          </Fade>
        </TextContainer>
        <ImgContainer
          lg={{ size: 4, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 1 }}
          xs={{ size: 10, offset: 1 }}
        >
          <div>
            <img
              alt="Suikerontharing Instituut Visage"
              src={img}
              title="Suikerontharing Instituut Visage"
            />
          </div>
        </ImgContainer>
      </Row>
    </Wrapper>
  );
};
