/* DEPRECATED */
import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

/* images */
import img from 'images/permanentemakeup.jpg';
import _dots from 'images/_dots.png';
/* shared components */
import { Button, LetterBlock } from 'components';

/* styled components */
const Wrapper = styled.div`
  margin: 100px 0;
  position: relative;
  h2{
    padding-top: 50px;
    margin-bottom: 100px;
    font-weight: 800;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.gray};
  }
  @media ${Size.lg}{
    h2{
      margin-bottom: 50px;
    }
  }
  @media ${Size.md}{
    h2{
      text-align: center;
      font-size: 1.5rem;
    }
  }
`;
const ImgContainer = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  div{
    position: relative;
    img{
      width: 100%;
      height: auto;
    }
    &::after{
      content: '';
      position: absolute;
      top: 20px;
      left: -8%;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      background-image: url(${_dots});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      z-index: -1;
    }
  }
`;
const TextContainer = styled(Col)`
  position: relative;
  padding: 20px 15px;
  p{
    margin-top: 15px;
    margin-bottom: 15px;
    width: 80%;
  }
  ul{
    list-style-type: disc;
    margin-top: 25px;
    margin-bottom: 25px;
    li{
      list-style-type: disc;
      margin-left: 15px;
    }
  }
  @media ${Size.md}{
    p{width: 100%;}
    ul{
      list-style-type: none;
      li{
        list-style-type: none;
        margin-left: 0;
        font-weight: bolder;
      }
    }
  }
`;

export const PermanenteMakeUp: FC = () => {
  return (
    <Wrapper>
      <Row>
        <Col
          lg={{ size: 5, offset: 6 }}
          md={{ size: 8, offset: 2 }}
          sm={{ size: 10, offset: 1 }}
          xl={{ size: 5, offset: 7 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade right>
            <h2>Permanente make-up</h2>
          </Fade>
        </Col>
      </Row>
      <LetterBlock char="p" onRight />
      <Row>
        <ImgContainer
          lg={{ size: 4, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 1 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade left>
            <div>
              <img alt="Permanente make-up Instituut Visage" src={img} title="Permanente make-up Instituut Visage" />
            </div>
          </Fade>
        </ImgContainer>
        <TextContainer
          lg={{ size: 5, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 2 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade bottom>
            <p>
              Wenkbrauwen en eyeliners geven kracht en expressie aan uw gelaat
            </p>
            <p>
              Permanente make-up is een pigmentatiemethode voor een blijvende of langdurige
              en natuurlijke make-up van wenkbrauwen en ogen. Subtiel en verfijnd! Permanent
              mooi is uw geheim voor een stralend uiterlijk.
            </p>
            <ul>
              <li><p>Vakkundig geplaatst</p></li>
              <li><p>De nodige uitleg voor en na</p></li>
              <li><p>Retouche in de behandeling inbegrepen</p></li>
            </ul>
          </Fade>
          <Button outline={false} text="Prijslijst" to="/prijslijst" />
        </TextContainer>
      </Row>
    </Wrapper>
  );
};
