import { FC, Fragment } from 'react';
import { Router } from './Router';
import { DEFAULT } from 'styled/theme';
import { Normalize } from 'styled-normalize';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styled/globalStyle';

// pages
import 'bootstrap/dist/css/bootstrap-grid.min.css';

export const App: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <meta
          content="Een essentiële ontmoeting, even op adem komen.. Saskia Comyn zaakvoerster van Instituut Visage
          ontvangt u op een vriendelijke en strikt persoonlijke manier."
          name="description" />
        <title>Instituut Visage | Om even op adem te komen </title>
        <link href="https://www.instituutvisage.be/" rel="canonical" />
      </Helmet>
      <Normalize />
      <ThemeProvider theme={DEFAULT}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </Fragment>
  );
};
