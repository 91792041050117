import { FC } from "react";
import styled, { Size } from "styled";
import { Row, Col } from "reactstrap";
import { v4 as uuid } from "uuid";

interface IItem {
  name: string;
  price: number;
}
export interface ICategory {
  name: string;
  subCategories?: SubCategory[];
}

export interface SubCategory {
  name: string;
  items: IItem[];
}

const Block = styled.div`
  background-color: ${({ theme }) => theme.bg};
  padding: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
`;
const TitleContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: ${({ theme }): string => theme.main};
    margin-bottom: 0;
    text-transform: uppercase;
  }
`;
const ItemsContainer = styled(Col)`
  padding: 10px;
`;
const Item = styled.li`
  margin-top: 10px;
  margin-bottom: 10px;
  list-style-type: none;

  @media ${Size.sm} {
    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
    }
  }
`;

const SubCategoryTitleContainer = styled.h3`
  text-align: center;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0px;
`;

export const Category: FC<ICategory> = ({ name, subCategories }) => {
  const currency = "€";
  const comma = ", 00";
  return (
    <Block id={name}>
      <Row>
        <TitleContainer lg="4" md="12" sm="12" xl="4" xs="12">
          <h2>{name}</h2>
        </TitleContainer>

        {subCategories?.map(({ name: subcategoryName, items }) => (
          <>
            <SubCategoryTitleContainer>
              {subcategoryName}
            </SubCategoryTitleContainer>
            <ItemsContainer lg="8" md="12" sm="12" xl="10" xs="12">
              {items.map(({ name, price }) => (
                <Item key={uuid()}>
                  <Row>
                    <Col lg="8" md="8" sm="8" xl="8" xs="7">
                      {name}
                    </Col>
                    <Col
                      className="price"
                      lg={{ size: 2, offset: 2 }}
                      md={{ size: 2, offset: 2 }}
                      sm={{ size: 3, offset: 1 }}
                      xl={{ size: 2, offset: 2 }}
                      xs={{ size: 4, offset: 1 }}
                    >
                      {price !== 0 ? currency + price + comma : ""}
                    </Col>
                  </Row>
                </Item>
              ))}
            </ItemsContainer>
          </>
        ))}
      </Row>
    </Block>
  );
};
