import { FC } from 'react';
import styled from 'styled';
import { Link } from 'react-router-dom';

const NavLink = styled(Link)`
  color: white;
  font-size: 1.4rem;
  transition: transform 0.3s ease-in-out;
  z-index: 500;
  cursor: pointer;
  &:hover{
    color: white;
    transform: translateY(-5px);
  }
`;

interface MobileNavLinkProps {
  to: string;
  text: string;
}

export const MobileNavLink: FC<MobileNavLinkProps> = ({ to, text }) => {
  return (
    <NavLink to={to}>{text}</NavLink>
  );
};
