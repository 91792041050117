import { FC, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FallbackContainer } from "components/Fallback";
import {
  SuperBoost,
  Wellness,
  PrivacyPolicy,
  PageNotFound,
  Ontdek,
  Contact,
  PrijsLijst,
  Afslanking,
} from "./pages";
import { Laserontharing } from "pages/Laserontharing";

const Frame = lazy(() => import("./components/Frame"));
const Home = lazy(() => import("./pages/Home"));

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <FallbackContainer>
            <img alt="" src="/svg-animation.svg" />
          </FallbackContainer>
        }
      >
        <Routes>
          <Route element={<Frame />}>
            <Route element={<Home />} path="/" />
            <Route element={<Afslanking />} path="/afslanking" />
            <Route element={<Wellness />} path="/wellness" />
            <Route element={<Ontdek />} path="/ontdek" />
            <Route element={<PrijsLijst />} path="/prijslijst" />
            <Route element={<Contact />} path="/contact" />
            <Route element={<PrivacyPolicy />} path="/privacy" />
            <Route element={<SuperBoost />} path="/superboost" />
            <Route element={<Laserontharing />} path="/laserontharing" />
            <Route element={<PageNotFound />} path="/*" />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
