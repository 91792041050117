import styled from 'styled-components';

export const FallbackContainer = styled.div`
  position: absolute;
  top: calc(50% - 15em);
  left: calc(50% - 15em);
  width: 30em;
  height: 30em;
  border-radius: 50%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content:center;
  transition: opacity 0.8s ease-in-out;
  img {
    width: 50%;
    height: 50%;
  }
`;
