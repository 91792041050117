import { FC } from 'react';
import styled, { Size } from 'styled';
import { keyframes } from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  margin-left: -20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media ${Size.sm}{
    margin-left: 0;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform:translateY(10px);
    opacity: 0.4;
    }
  60%{
    opacity: 0;
  }
  100% {
    transform:translateY(0);
    opacity: 0;
    }
`;
const M = styled.div`
  width: 28px;
  height: 44px;
  border-radius: 12px;
  border: 3px solid ${({ theme }): string => theme.main};
  text-align: center;
  margin-bottom: 3px;
  cursor: pointer;
  z-index: 9999;
  &:after {
    display: inline-block;
    width: 6px;
    height: 6px;
  background-color: ${({ theme }): string => theme.main};
    border-radius: 50%;
    content: "";
  animation: ${scroll} 2s ease-out infinite forwards;
  }
`;
const Caption = styled.div`
  color: ${({ theme }) => theme.main};
  font-weight: 600;
@media ${Size.sm}{
  display: none;
}
`;

export const Mouse: FC = () => {
  return (
    <Wrapper>
      <M />
      <Caption>scroll down</Caption>
    </Wrapper>
  );
};
