import { FC } from 'react';
import GoogleMapReact from 'google-map-react';

import mapStyle from './map.json'; // grey map
import styled from 'styled';
import icon from 'images/icon/icon.svg';

const Img = styled.img`
  width: 50px;
  height: 50px;
`;

/*

old:
withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '400px', backgroundColor: 'gray' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
*/

const VISAGE_CENTER = { lat: 50.9963337, lng: 3.422358 };

export const Map: FC = () => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDaKNjoyrbgLNZjKlIDeZCXYCfEob-qzUY' }}
      defaultCenter={VISAGE_CENTER}
      defaultZoom={18}
      options={{
        styles: mapStyle,
        zoomControl: true,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        draggable: false,
        zoomControlOptions: { position: 7 },
        keyboardShortcuts: false,
        maxZoom: 18,
        minZoom: 14
      }}
    >
      <Img alt="logo visage" src={icon} />
      {/*    <img alt="logo visage" src={icon} />*/}
    </GoogleMapReact>
  );

  /*
    return (
      <GoogleMap
        defaultCenter={defaultProps.center}
        defaultOptions={{
          // @ts-ignore
          styles: MapStyle,
          zoomControl: true,
          streetViewControl: false,
          navigationControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          scrollwheel: false
        }}
        defaultZoom={defaultProps.zoom}>
        <Marker
          icon={{
            url: icon,
            scale: 50
          }}
          position={{
            lat: 50.99615337,
            lng: 3.422388
          }} />
      </GoogleMap>
    );
    */
};
