import { FC } from 'react';
import styled from 'styled';
import { IoIosArrowDown } from 'react-icons/io';

/* styled components */
const Wrapper = styled.div`
  cursor: pointer;
  margin-top: 50px;
  svg{
    width: 48px;
    height: auto;
    color: white;
  }
`;

export const Close: FC = () => {
  function hideMobileMenu() {
    // show the mobile menu on top.
    const nav = document.querySelector('#mobilenav')!;
    nav.classList.toggle('nav--open');
  }

  return (
    <Wrapper onClick={hideMobileMenu}>
      <IoIosArrowDown />
    </Wrapper>
  );
};
