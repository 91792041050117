import { FC } from 'react';
import styled, { Size } from 'styled';
import voorNa1 from 'images/superboost/voor-na-1.png';
import voorNa2 from 'images/superboost/voor-na-2.jpeg';

const Container = styled.div`
  width: 70%;
  margin: 0 auto;
  background-color: ${({ theme }): string => theme.bg};
  padding: 40px;
  margin-bottom: 150px;

  h2 {
    color: ${({ theme }) => theme.main};
    font-size: 2rem;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 1.335rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    flex-direction: row;
    max-height: 400px;
    margin: 50px auto;
  }

  @media ${Size.lg}{
    flex-direction: column;
  }
`;

export const Result: FC = () => {
  return (
    <Container>
      <h2>Resultaat</h2>
      <p>Een ontspannen strakker gelaat met een mooiere huidtint. Je ziet meteen resultaat en het voelt super!</p>
      <ImageContainer>
        <img alt="Superboost voor-na-1" src={voorNa1} />
        <img alt="Superboost voor-na-2" src={voorNa2} />
      </ImageContainer>
    </Container>
  );
};
