import { FC } from 'react';
import styled from 'styled';
import { Link } from 'react-router-dom';

/* styled components */
const DefaultButton = styled(Link)`
  text-decoration: none;
  position: relative;
  padding: 8px 30px;
  border: 2px solid;
  border-radius: 20px;
  width: fit-content;
  border-color: ${({ theme }): string => theme.main};
  font-weight: 600;
  vertical-align: middle;
  font-size: 1rem;
  color: white;
  background-color: ${({ theme }): string => theme.main};
  transition: 0.3s transform ease-in-out;
  &:hover{
    transform: translate(10px, 0);
    color: white;
  }
`;
const OutlineButton = styled(DefaultButton)`
  background-color: transparent;
  border-color: white;
  color: white;
`;
interface ButtonProps {
  text: string;
  to: string;
  outline?: boolean;
  className?: string;
};

export const Button: FC<ButtonProps> = ({ className, text, to, outline = false }) => {
  if (!outline) {
    return (<DefaultButton className={className} to={to}>{text}</DefaultButton>);
  }
  return (<OutlineButton className={className} to={to}>{text}</OutlineButton>);
};
