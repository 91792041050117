import { FC, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Footer, MobileMenu, Landing } from "components";
import landingimg from "images/laserontharing/legs.jpg";
import img from "images/laserontharing/device.png";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";
import styled, { Size } from "styled";
import { AantalBehandelingen } from "views/laserontharing/AantalBehandelingen";
import { HoeWerktHet } from "views/laserontharing/HoeWerktHet";
import { VoorWie } from "views/laserontharing/VoorWie";

const InfoContainer = styled.div`
  margin: 150px 0;
`;

const TextContainer = styled(Col)`
  h2 {
    font-size: 3.5rem;
    color: ${({ theme }): string => theme.main};
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;
const Line = styled.div`
  width: 10%;
  height: 5px;
  border-radius: 5px;
  background-color: ${({ theme }): string => theme.gray};
  margin-bottom: 25px;
`;

const ImgContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 350px;
  }
  @media ${Size.md} {
    display: none;
  }
`;

export const Laserontharing: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Instituut Visage | Superboost </title>
        <meta
          content="De beauty behandeling van de toekomst. Superboost is de meest effective &amp; duurzame methode voor een gegarandeerde strakke, frisse huid. Superboost geeft jou de zachtheid van een cosmetische verzorging (pijnloos) met de impact van een medische behandeling."
          name="description"
        />
      </Helmet>
      <MobileMenu />
      <Landing img={landingimg} imgPosition="bottom" title="Laserontharing" />

      <InfoContainer>
        <Container>
          <Row>
            <TextContainer lg="6" md="12" xl="6">
              <h2>
                <Fade top>Venus Epileve</Fade>
              </h2>
              <Line />
              <p>
                Vaarwel scheermes, hallo laser! Venus Epilève zorgt voor
                effectieve, snelle en comfortabele ontharing van gezicht en
                lichaam met behulp van de modernste lasertechnologie. Nooit meer
                eindeloos scheren, vervelend epileren, pijnlijk harsen, knoeien
                met scherpe scheermesjes en rommelige ontharingscrèmes! Venus
                Epilève laserontharing haalt de stress uit haarverwijdering met
                comfortabele, ultrasnelle behandelingen, zodat je eindelijk
                blijvend kan genieten van de schoonheid van een gladde,
                haarvrije huid.
              </p>
            </TextContainer>
            <ImgContainer
              lg={{ size: 4, offset: 1 }}
              md={{ size: 12, offset: 0 }}
              xl={{ size: 4, offset: 1 }}
            >
              <img
                alt="Venus epileve lasergun"
                src={img}
                title="Venus Epileve lasergun"
              />
            </ImgContainer>
          </Row>
        </Container>
      </InfoContainer>
      <HoeWerktHet />
      <AantalBehandelingen />
      <VoorWie />
      <Footer />
    </Fragment>
  );
};
