import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
import { Footer, Landing } from 'components';
import { CadeauBon, HotStone, Huwelijk } from 'views/wellness';

/* images */
import img from 'images/_wellness2.jpg';

const Line = styled.div`
  width: 10px;
  height: 100px;
  background-color: ${({ theme }): string => theme.bg};
  @media ${Size.xs}{
    display:none;
  }
`;

export const Wellness: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Instituut Visage | Wellness</title>
      </Helmet>
      <Landing img={img} imgPosition="right" title="wellness" />
      <CadeauBon />
      <HotStone />
      <Row>
        <Col
          lg={{ size: 8, offset: 4 }}
          md={{ size: 8, offset: 4 }}
          sm={{ size: 8, offset: 4 }}
          xl={{ size: 8, offset: 4 }}>
          <Line />
        </Col>
      </Row>
      <Huwelijk />
      <Footer />
    </Fragment>
  );
};
