import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled, { Size } from 'styled';
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';

/* images */
import landingimg from 'images/afslanking.jpg';
import restartimg from 'images/afslanking/restart.jpg';
/* shared components */
import { Landing, Footer } from 'components';
import { StepOne, StepTwo, StepThree } from 'views/afslanking';

const InfoContainer = styled.div`
  margin: 150px 0;
`;
const TextContainer = styled(Col)`
  h2{
    font-size: 3.5rem;
    color: ${({ theme }): string => theme.main};
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;
const Line = styled.div`
    width: 10%;
    height: 5px;
    border-radius: 5px;
    background-color: ${({ theme }): string => theme.gray};
    margin-bottom: 25px;
`;
const ImgContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  img{
  width: auto;
  height: 250px;
  filter: grayscale(1);
  }
  @media ${Size.md}{
    display: none;
  }
`;

const NoMarginTopFooter = styled(Footer)`
  margin-top: 5px;
`;

export const Afslanking: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Instituut Visage | Afslanking</title>
        <meta
          content="Ontdek bij Visage het Restart dieet. Het is niet zomaar een diet... It's a new way of life. Interesse in een gezond, fit en slank lichaam? Kom langs of bel voor een gratis body scan en uitleg over het dieet."
          name="description" />
      </Helmet>
      <Landing img={landingimg} imgPosition="right" title="Afslanking" />
      <InfoContainer>
        <Container>
          <Row>
            <TextContainer lg="6" md="12" xl="6">
              <h2><Fade top>Restart</Fade></h2>
              <Line />
              <p>
                Restart your body is een voedingsplan met een doel. Je legt samen met je coach
                een traject af waardoor je je beter zal gaan voelen en afslanken.
                Je leert je lichaam beter kennen, wordt je eigen coach en leert organiseren.
                Onderweg ontdek je welke voeding voor jou oké is en welke niet zodat je achteraf
                je gewicht perfect kan behouden. Restart bestaat uit 100% natuurlijke voeding
                (geen shakes) en wordt gecombineerd met een energetische ampul en coaching..
              </p>
            </TextContainer>
            <ImgContainer
              lg={{ size: 4, offset: 1 }}
              md={{ size: 12, offset: 0 }}
              xl={{ size: 4, offset: 1 }}>
              <img alt="Restart dieet Aarsele" src={restartimg} title="Restart Dieet Aarsele" />
            </ImgContainer>
          </Row>
        </Container>
      </InfoContainer>
      <StepOne />
      <StepTwo />
      <StepThree />
      <NoMarginTopFooter />
    </Fragment>
  );
};
