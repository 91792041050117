import { FC, Fragment, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { ThemeContext } from "styled-components";
import styled, { Size } from "styled";
import { Container, Row, Col } from "reactstrap";
import ProgressBar from "react-scroll-progress-bar";
import { Hamburger, Tag } from "components";

/* images */
import icon from "images/icon/icon.svg";

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 50px;
  background-color: ${({ theme }): string => theme.bg};

  @media ${Size.md} {
    height: 125px;
  }
`;
const TransparentWrapper = styled(Wrapper)`
  background-color: transparent;
  padding: 65px 50px;
`;
const IconContainer = styled(Col)`
  @media ${Size.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Icon = styled.img`
  height: 100px;
  margin-left: 75px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateX(-10px);
  }
  @media ${Size.md} {
    height: 75px;
  }
  @media ${Size.xs} {
    display: none;
    height: 65px;
    margin: 0 auto 25px auto;
  }
`;
const NavContainer = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 25px;

  @media ${Size.md} {
    display: none;
  }
`;
const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }): string => theme.gray};
  margin-right: 15px;
  margin-left: 15px;
  transition: 0.2s transform ease-in-out;
  font-weight: 500;

  div {
    margin-right: 15px;
  }

  &:hover {
    color: ${({ theme }): string => theme.gray};
    transform: translateY(-3px);
  }
  &.active {
    color: ${({ theme }): string => theme.main};
    font-weight: 500;
  }
  @media ${Size.md} {
    padding-bottom: 25px;
  }
`;
const FixedProgressBar = styled(ProgressBar)`
  z-index: 1000;
`;

interface HeaderProps {
  transparent?: boolean;
}

export const Header: FC<HeaderProps> = ({ transparent = false }) => {
  const theme = useContext(ThemeContext);

  if (transparent) {
    return (
      <Fragment>
        <FixedProgressBar bgcolor={theme.main} height="8px" />
        <TransparentWrapper>
          <Container fluid>
            <Row>
              <IconContainer lg="2" md="4" sm="5" xl="3" xs="12">
                <Link to="/">
                  <Icon
                    alt="Logo Instituut Visage"
                    src={icon}
                    title="Logo Instituut Visage"
                  />
                </Link>
              </IconContainer>
              <Col
                lg="10"
                md={{ size: 8, offset: 0 }}
                sm={{ size: 7, offset: 0 }}
                xl="9"
                xs={{ size: 12, offset: 0 }}
              >
                <NavContainer>
                  <StyledNavLink to="/laserontharing">
                    <Tag text="Nieuw!" />
                    Laserontharing
                  </StyledNavLink>
                  <StyledNavLink to="/superboost">Superboost</StyledNavLink>
                  <StyledNavLink to="/afslanking">Afslanking</StyledNavLink>
                  <StyledNavLink to="/wellness">Wellness</StyledNavLink>
                  <StyledNavLink to="/ontdek">Ontdek</StyledNavLink>
                  <StyledNavLink to="/prijslijst">Prijslijst</StyledNavLink>
                  <StyledNavLink to="/contact">Contact</StyledNavLink>
                </NavContainer>
                <Hamburger />
              </Col>
            </Row>
          </Container>
        </TransparentWrapper>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <FixedProgressBar bgcolor={theme.main} height="8px" />
      <Wrapper>
        <Container fluid>
          <Row>
            <IconContainer lg="2" md="4" sm="5" xl="3" xs="12">
              <Link to="/">
                <Icon alt="Icoon Instituut Visage" src={icon} />
              </Link>
            </IconContainer>
            <Col
              lg="10"
              md={{ size: 8, offset: 0 }}
              sm={{ size: 7, offset: 0 }}
              xl="9"
              xs={{ size: 12, offset: 0 }}
            >
              <NavContainer>
                <NavLink to="/laserontharing">Laserontharing</NavLink>
                <NavLink to="/superboost"> Superboost</NavLink>
                <NavLink to="/afslanking"> Afslanking</NavLink>
                <NavLink to="/wellness"> Wellness</NavLink>
                <NavLink to="/ontdek"> Ontdek</NavLink>
                <NavLink to="/prijslijst"> Prijslijst</NavLink>
                <NavLink to="/contact"> Contact</NavLink>
              </NavContainer>
              <Hamburger />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Fragment>
  );
};
