import { FC } from "react";
import styled, { Size } from "styled";
import { Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

/* images */
import img from "images/laserontharing/leg-slide.jpg";
import _dots from "images/_dots.png";
/* shared components */
import { Button, LetterBlock } from "components";

/* styled components */
const Wrapper = styled.div`
  margin: 100px 0;
  position: relative;
  h2 {
    padding-top: 50px;
    margin-bottom: 100px;
    font-weight: 800;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.gray};
  }
  @media ${Size.lg} {
    h2 {
      margin-bottom: 50px;
    }
  }
  @media ${Size.md} {
    h2 {
      text-align: center;
      font-size: 1.5rem;
    }
  }
`;
const ImgContainer = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  div {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      left: -8%;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      background-image: url(${_dots});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      z-index: -1;
    }
  }
`;
const TextContainer = styled(Col)`
  position: relative;
  padding: 20px 15px;
  p {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 80%;
  }
  ul {
    list-style-type: disc;
    margin-top: 25px;
    margin-bottom: 25px;
    li {
      list-style-type: disc;
      margin-left: 15px;
    }
  }
  @media ${Size.md} {
    p {
      width: 100%;
    }
    ul {
      list-style-type: none;
      li {
        list-style-type: none;
        margin-left: 0;
        font-weight: bolder;
      }
    }
  }
`;

export const AantalBehandelingen: FC = () => {
  return (
    <Wrapper>
      <Row>
        <Col
          lg={{ size: 5, offset: 6 }}
          md={{ size: 8, offset: 2 }}
          sm={{ size: 10, offset: 1 }}
          xl={{ size: 5, offset: 7 }}
          xs={{ size: 10, offset: 1 }}
        >
          <Fade right>
            <h2>Aantal behandelingen</h2>
          </Fade>
        </Col>
      </Row>
      <LetterBlock char="A" onRight />
      <Row>
        <ImgContainer
          lg={{ size: 4, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 1 }}
          xs={{ size: 10, offset: 1 }}
        >
          <Fade left>
            <div>
              <img alt="" src={img} title="" />
            </div>
          </Fade>
        </ImgContainer>
        <TextContainer
          lg={{ size: 5, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 2 }}
          xs={{ size: 10, offset: 1 }}
        >
          <Fade bottom>
            <p>
              Het haar kent 3 groeifases. De laser richt zich enkel op de
              gepigmenteerde haren. Enkel de haren in de actieve- of anagene
              fase, worden definitief verwijderd. Ongeveer 30% van het haar
              bevindt zich op een bepaald moment in deze fase, daarom heb je
              meerdere behandelingen nodig om resultaten te zien.
            </p>
            <p>
              Voor een effectief resultaat heeft u tussen de{" "}
              <strong>4 à 8 behandelingen </strong>
              nodig. Het aantal behandelingen hangt af van jouw huidtype,
              haargroei, … Deze worden{" "}
              <strong>om de 4 à 12 weken herhaald</strong>, afhankelijk van de
              behandelzone.
            </p>
          </Fade>
          <Button outline={false} text="Prijslijst" to="/prijslijst" />
        </TextContainer>
      </Row>
    </Wrapper>
  );
};
