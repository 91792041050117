import { createGlobalStyle } from 'styled-components';
import { ThemeType } from './theme';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  ::selection {
    background-color: ${({ theme }): string => theme.main};
    color: white;
  }

  html, body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: ${({ theme }): string => theme.gray};
  overflow-x: hidden;
  line-height: 1.5;
  transition: width 0.3s ease-in-out;
}

h1, h2, h3, h4, h5, h6, a, p, div {
  outline: 0;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
}

h3 {
  font-size: 1.1rem;
  margin-bottom: 25px;
  font-weight: 600;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.825rem;
}

a {
  text-decoration: none;
  color: ${({ theme }) => theme.gray};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.gray};
  }
}

button {
  outline: 0;
  &:focus {
    outline: 0;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    list-style-type: none;
  }
}

div {
  padding: 0;
}

.app{
  overflow-x:hidden;
  max-width: 100vw;
}


/* afslanking*/
h3.step {
  color: ${({ theme }): string => theme.main};
  font-size: 2rem;
  text-transform: uppercase;
}

/*
  google maps:
  these tags make sure the error codes, google logo, and other text
  that could appear on the google map don't show. creating a cleaner map.
  check contact.js for more information.
*/
a[href^="http://maps.google.com/maps"] {
  display: none !important;
}
a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

#mobilenav.nav--open{
  height: 100vh;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.switch-wrapper {
  position: relative;
}
.switch-wrapper > div {
  position: absolute;
}
/* page transition */
.page {
  top: 0;
  width: 100%;
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out 500ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}


/* loader */
#root{
  transition: opacity 1s linear;
  overflow-x: hidden;
  max-width: 100vw;
}
.root--show{
  opacity: 1!important;
}
`;
