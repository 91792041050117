import { FC } from 'react';
import styled, { Size } from 'styled';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

/* images */
import giftcard from 'images/giftcard.jpg';

const Wrapper = styled.div`
  margin: 150px 0;

  @media ${Size.sm}{
    margin: 75px 0;
  }
`;
const ImgContainer = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const Large = styled.img`
  width: 100%;
  height: auto;
  box-shadow: 17px 17px 15px -4px rgba(0,0,0,0.1);
`;
const TextContainer = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction:column;
  padding: 100px 0;
  p{
    margin-bottom: 15px;
    margin-top: 15px;
  }
  @media ${Size.sm}{
    padding: 50px 0;
  }
  @media ${Size.xs}{
    padding: 50px 20px 0 20px;
  }

`;
export const CadeauBon: FC = () => {
  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <ImgContainer
            lg={{ size: 5, offset: 1 }}
            md={{ size: 5, offset: 1 }}
            sm={{ size: 10, offset: 1 }}
            xl={{ size: 5, offset: 1 }}
            xs={{ size: 10, offset: 1 }}>
            <Large alt="changeme" src={giftcard} title="changeme" />
          </ImgContainer>
          <TextContainer
            lg={{ size: 4, offset: 1 }}
            md={{ size: 5, offset: 1 }}
            sm={{ size: 8, offset: 2 }}
            xl={{ size: 4, offset: 1 }}
            xs={{ size: 10, offset: 1 }}>
            <h2><Fade top>Cadeaubon nodig?</Fade></h2>
            <p>
              Bent u op zoek naar een cadeau voor een verjaardag, moederdag,
              de feestdagen of wilt u iemand bedanken? Schenk een cadeaubon
              van Schoonheidsinstituut Visage.
            </p>
            <p>
              De bonnen zijn bij elke gelegenheid te verkrijgen en kunnen opgesteld
              worden voor een bedrag of ter waarde van een behandeling. De waardebon blijft
              dan geldig voor onbepaalde duur!
            </p>
          </TextContainer>
        </Row>
      </Container>
    </Wrapper>
  );
};
