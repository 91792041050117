import { FC, Fragment } from 'react';
import styled, { Size } from 'styled';
import { Container, Row, Col } from 'reactstrap';
import { Header, MobileMenu, Footer } from 'components';

const Wrapper = styled.div`
  padding-bottom: 200px;
`;
const TitleContainer = styled.div`
  text-align: center;
  color: ${({ theme }): string => theme.main};
  margin-top: 25vh;
  margin-bottom: 100px;
  h1{
    font-weight: 500;
    font-size: 5rem;
    margin-bottom: 5px;
  }
  @media ${Size.sm}{
    h1{font-size: 3.25rem;}
  }
  @media ${Size.xs}{
    h1{font-size: 2.8rem }
  }
`;
const General = styled.div`
  padding-bottom: 50px;
  h2{
    font-size: 3.5rem;
    text-transform: uppercase;
    color: ${({ theme }): string => theme.main};
    margin-bottom: 20px;
    margin-top: 50px;
  }
  @media ${Size.sm}{
    h2{
    font-size: 2rem;
    }
  }
`;
const Cookies = styled.div`
  h3{
    font-size: 1.325rem;
    font-weight: 600;
    margin-top: 20px;
  }
  h4{
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .inline{
    margin-left: 0;
  }
  p, h4{
    margin-left: 20px;
  }
 `;
const ThirdParty = styled.div`
  margin-top: 50px;
  li{
    margin-bottom: 10px;
    margin-top: 10px;
    list-style-type: disc;
    margin-left: 30px;
      a{
        display: block;
        font-weight: 700;
      }
    }
 `;
const DisableCookies = styled.div`
  margin-top: 50px;
  ul{
    margin-bottom: 20px;
  }
  li{
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 30px;
    list-style-type: disc;
    a{
      font-weight: 700;
    }
  }
`;
const Contact = styled.div`
  margin-top: 50px;
  ul{
    margin-top: 20px;
  }
  li{
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 30px;
    list-style-type: disc;
  }
`;

export const PrivacyPolicy: FC = () => {
  return (
    <Fragment>
      <MobileMenu />
      <Header />
      <Wrapper>
        <TitleContainer>
          <h1>Privacy disclaimer</h1>
          <p>Laatst gewijzigd op 17 okt 2020</p>
        </TitleContainer>
        <Container>
          <Row>
            <Col xl={{ size: 10, offset: 1 }}>
              <General>
                <h2>Algemeen</h2>
                <p>
                  Uw persoonsgegevens zijn heel belangrijk voor ons. Wij maken er dan ook een punt
                  van om die te beschermen! Bij het gebruik van uw persoonsgegevens houden wij
                  rekening met de rechten die u hebt volgens de wet. Alle data
                  die Institituut Visage verzamelt is anonieme data. Deze data wordt enkel
                  en alleen gebruikt om de gebruiksvriendelijkheid en performantie van de website
                  te verbeteren. De verzameling van data maakt hiervoor gebruik van cookies.
                </p>
              </General>
              <Cookies>
                <h3>1. Wat is een cookie</h3>
                <p className="inline">
                  Een cookie is een klein databestand dat wordt opgeslagen op uw apparaat.
                  Een cookie isoleert geen informatie. Maar wanneer gelezen door een server
                  via uw browser, kan deze informatie bezorgen voor een meer gebruiksvriendelijke
                  ervaring. Denk maar aan taalkeuze, apparaat identificatie, data voor
                  statistieken...
                </p>
                <h3>2. Welk soorten cookies gebruikt Instituut Visage</h3>
                <h4>
                  Strikt noodzakelijke cookies:
                </h4>
                <p>
                  Zoals de naam suggereert, deze cookies zijn strikt noodzakelijk om te
                  kunnen surfen op deze website.
                </p>
                <h4>Performantie cookies:</h4>
                <p>
                  Deze cookies hebben geen explicitie toestemming nodig. Deze cookies analyseren
                  en monitoren deze website. De informatie hieruit helpt ons begrijpen hoe mensen
                  onze diensten en website gebruiken. Het geeft informatie over de ervaring van de
                  gebruiker. Dit alles helpt ons om uw ervaring met Instituut Visage te verbeteren.
                </p>
                <h4>Tracking cookies:</h4>
                <p>
                  Deze cookies blijven bestaan na de sessie. De maximale levensduur is
                  momenteel ingesteld op één jaar. In dat jaar wordt de initiële waarde -
                  opgeslagen in de vorm van een cookie - naar de server verstuurt als u de
                  website opnieuw bezoekt. Dit geeft ons belangrijke informatie, zoals hoe
                  de gebruiker oorspronkelijk op deze website terecht kwam. Om deze reden
                  worden ze tracking cookies genoemd. Bijvoorbeeld, eens u de gewenste taal
                  geselecteerd heeft, zal dit opgeslagen worden in een cookie. Als u onze website
                  opnieuw bezoekt, zal de site onmiddellijk de juiste taal weergeven.
                </p>
                <h4>Third party cookies:</h4>
                <p>
                  Om onze communicatie te versterken zorgen we onder andere voor anchors
                  met social media kanalen zoals Facebook, Instagram, LinkedIn. Sommige
                  van deze kanalen kunnen gebruik maken van cookies. Instituut Visage verifieert
                  deze cookies niet. Hiervoor moet u het cookiebeleid van het kanaal raadplegen.
                </p>
              </Cookies>
              <ThirdParty>
                <h2>3. Third party cookies</h2>
                <p>
                  Deze cookies kunnen opgeslagen worden op uw computer door onze website:
                </p>
                <ul>
                  <li>
                    Google: Google analytics / google apis / adwords.
                    <a href="https://www.google.com/policies/technologies/types">https://www.google.com/policies/technologies/types</a>
                  </li>
                </ul>
              </ThirdParty>
              <DisableCookies>
                <h2>4. Weigeren of uitschakelen van cookies</h2>
                <p>
                  U kan de cookies ook uitschakelen, verwijderen of de instellingen
                  voor cookies aanpassen, al zal onze website in dat geval niet optimaal
                  meer kunnen werken. Wilt u de instellingen voor cookies toch aanpassen?
                  Dan kan u hier de instructies voor verschillende browsers terugvinden.
                </p>
                <ul>
                  <li><a href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen">Mozilla Firefox</a></li>
                  <li><a href="https://support.google.com/chrome/answer/95647?hl=nl">Google Chrome</a></li>
                  <li><a href="https://support.microsoft.com/nl-nl/help/10607/microsoft-edge-view-delete-browser-history">Microsoft Edge</a></li>
                  <li><a href="https://support.microsoft.com/nl-nl/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                </ul>
                <p>
                  Graag zou ik hieraan willen toevoegen dat cookies een extra waarde
                  zijn voor uw surfervaring op het web. Uitschakelen is afgeraden.
                  Indien u meer wilt weten over cookies, surf naar
                  <a href="www.allaboutcookies.org"> www.allaboutcookies.org.</a>
                  Deze site biedt meer informatie over cookies, legt in detail uit
                  hoe u cookies kan verwijderen en toont hoe u uw vertrouwde sites
                  toegang tot cookies kan verschaffen.
                </p>
              </DisableCookies>
              <Contact>
                <h2>5. Contactgegevens</h2>
                <p>
                  Indien u verder nog vragen heeft of ons wenst te contacteren
                  in het kader van deze privacyverklaring, kan u ons bereiken via
                  onderstaande mogelijkheden.
                </p>
                <ul>
                  <li>Emiel Van Severen</li>
                  <li>info@massief.biz</li>
                  <li>0479 12 97 18</li>
                </ul>
              </Contact>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <Footer />
    </Fragment>
  );
};
