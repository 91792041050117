import { FC, Fragment } from 'react';
import styled, { Size } from 'styled';
import { Button } from 'components';
/* images */
import icon from 'images/icon/icon.svg';
/* shared components */

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1{
    color: ${({ theme }): string => theme.main}
  }
  img{
    width: 200px;
    height: auto;
    margin-bottom: 50px;
  }

  @media ${Size.sm}{
    text-align: center;
    h1{
      font-size: 1.5rem;
    }
  }
  @media ${Size.xs}{
    img{
      width: 150px;
    }
    a{
      font-size: 0.8rem;
      padding: 8px 15px;
    }
  }
`;
export const PageNotFound: FC = () => {
  return (
    <Fragment>
      <Wrapper>
        <h1>Oei de pagina kon niet gevonden worden..</h1>
        <img alt="Instituut Visage" src={icon} title="Instituut Visage" />
        <Button outline={false} text="terugkeren naar de homepage" to="/" />
      </Wrapper>
    </Fragment>
  );
};
