import { FC } from 'react';
import styled, { Size } from 'styled';
import { Fade } from 'react-reveal';

/* styled components */
const Wrapper = styled.div<{ passing: string }>`
  width: 400px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bg};
  margin-left: ${props => props.passing};

  @media ${Size.md}{
    width: 250px;
    height: 125px;
  }
  @media ${Size.xs}{
    width: 175px;
    height: 87.5px;
  }
`;

const Number = styled.h3`
  color: white;
  font-size: 10rem;
  font-weight: bolder;
  margin-bottom: 0;

  @media ${Size.md}{
    font-size: 6rem;
  }
  @media ${Size.xs}{
    font-size: 3.5rem;
  }
`;
interface StepBlockProps {
  side: string;
  value: string;
}

export const StepBlock: FC<StepBlockProps> = ({ value, side }) => {
  let passing;
  side === 'left' ? passing = '0' : passing = 'auto';

  return (
    <Wrapper passing={passing}>
      <Number><Fade bottom>{value}</Fade></Number>
    </Wrapper>
  );
};
