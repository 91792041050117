import { FC } from 'react';
import styled, { Size } from 'styled';
import { css } from 'styled-components';
/* styled components */
const SharedStyle = css`
  color: ${({ theme }): string => theme.main};
  font-size: 10rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  @media ${Size.xs}{
    font-size: 5rem;
  }
`;
const HeaderOne = styled.h1`
  ${SharedStyle};
`;
const HeaderTwo = styled.h2`
  ${SharedStyle};
`;

interface TitlePartsProps {
  isH1: boolean;
  partOne: string;
  partTwo: string;
}

export const TitleParts: FC<TitlePartsProps> = ({ isH1, partOne, partTwo }) => {
  return (
    isH1
      ? (
        <HeaderOne>
          {partOne}
          <br />
          {partTwo}
        </HeaderOne>
      )
      : (
        <HeaderTwo>
          {partOne}
          <br />
          {partTwo}
        </HeaderTwo>
      )
  );
};
