import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import withReveal from 'react-reveal/withReveal';
import { v4 as uuid } from 'uuid';
import { Letter, TitleParts } from 'components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg};
  padding: 100px 25px;
  margin: 150px 25px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media ${Size.xs}{
    padding: 100px 0 25px 0;
  }
`;
const TitleContainer = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${Size.lg}{
    h2{
      font-size: 7rem;
    }
  }
  @media ${Size.md}{
    h2{
      font-size: 5rem;
    }
  }
  @media ${Size.xs}{
    h2{
      font-size: 4rem;
    }
  }
`;
const AbsoluteLetter = styled(Letter)`
  position: absolute;
  bottom: -18%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 24rem;
  @media ${Size.sm}{
    font-size: 15rem;
    bottom: 0;
    top:  -55%;
    left: 80%;
  }
  @media ${Size.xs}{
    left: 70%;
    font-size: 10rem;
    top: -67%;
  }
`;
const Item = withReveal(styled.li`
  margin-top: 25px;
  margin-bottom: 25px;
`, <Fade top />);

const data = [
  {
    title: 'Mini-stone therapie',
    desc: 'Lavasteenmassage van rug, nek en schouders'
  },
  {
    title: 'Hot-stone therapie',
    desc: 'Een volledige lichaamsmassage met warme lavastenen, unieke en zachte relaxatie, losse spieren, ontgifting, heropladen'
  },
  {
    title: 'Spa-therapie',
    desc: 'Lavasteenmassage van rug, nek en schouders + een volledige spa gelaatsverzorging'
  },
  {
    title: 'Anti-age-therapie',
    desc: 'Lavasteenmassage van rug, nek en schouders + een anti-rimpel gelaatsverzoring met hyaluronzuur en collageen (anti-rimpel en verstevigend)'
  },
  {
    title: 'Special men-therapie',
    desc: 'Lavasteenmassage van rug, nek en schouders + een zuiverende anti-stress gelaatsverzorging met producten afgestemd op de huid van de man'
  }
];

export const HotStone: FC = () => {
  return (
    <Wrapper>
      <Row>
        <TitleContainer
          lg={{ size: 4, offset: 1 }}
          md={{ size: 4, offset: 1 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 5, offset: 1 }}
          xs={{ size: 10, offset: 1 }}>
          <TitleParts isH1={false} partOne="hot" partTwo="stone" />
          <AbsoluteLetter char="s" isWhite />
        </TitleContainer>
        <Col
          lg={{ size: 5, offset: 1 }}
          md={{ size: 5, offset: 1 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 1 }}
          xs={{ size: 10, offset: 1 }}>
          <ul>
            {data.map(item => (
              <Item key={uuid()}>
                <h2>{item.title}</h2>
                <p>{item.desc}</p>
              </Item>
            ))
            }
          </ul>
        </Col>
      </Row>
    </Wrapper>
  );
};
