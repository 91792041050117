import { FC } from 'react';
import styled, { Size } from 'styled';
import hamburger from 'images/nav/hamburger.svg';

/* styled components */
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Icon = styled.img`
  display: none;
  width: 48px;
  height: auto;
  fill: ${({ theme }): string => theme.main};
  cursor: pointer;

  @media ${Size.md}{
    display: block;
  }
  @media ${Size.xs}{
    margin: 0 auto;
  }
`;

export const Hamburger: FC = () => {
  function showMobileMenu() {
    // show the mobile menu on top.
    const nav = document.querySelector('#mobilenav')!;
    nav.classList.toggle('nav--open');
  }

  return (
    <Wrapper>
      <Icon alt="menu Schoonheidsinstituut Visage" onClick={showMobileMenu} src={hamburger} title="menu Schoonheidsinstituut Visage" />
    </Wrapper>
  );
};
