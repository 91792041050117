import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

/* images */
import img from 'images/superboost/biorefpen.jpeg';
import _dots from 'images/_dots.png';
/* shared components */
import { Button, LetterBlock } from 'components';

/* styled components */
const Wrapper = styled.div`
  margin: 100px 0;
  position: relative;
  h2{
    padding-top: 50px;
    margin-bottom: 50px;
    font-weight: 800;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.gray};
  }
  @media ${Size.lg}{
    h2{
      margin-bottom: 50px;
    }
  }
  @media ${Size.md}{
    h2{
      text-align: center;
      font-size: 1.5rem;
    }
  }
`;
const ImgContainer = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  div{
    position: relative;
    img{
      width: 80%;
      height: auto;
    }
    &::after{
      content: '';
      position: absolute;
      top: 20px;
      left: -8%;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      background-image: url(${_dots});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      z-index: -1;
    }
  }
`;
const TextContainer = styled(Col)`
  position: relative;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  ul{
    list-style-type: disc;
    margin-bottom: 25px;
    li{
      list-style-type: disc;
      margin: 5px 0 5px 15px
    }
  }

  p{
    margin-top: 15px;
    margin-bottom: 15px;
    width: 80%;

    strong {
      color: ${({ theme }): string => theme.main};
    }
  }
  @media ${Size.md}{
    p{width: 100%;}
    ul{
      li{
        font-weight: bolder;
      }
    }
  }
`;

export const Basic: FC = () => {
  return (
    <Wrapper>
      <Row>
        <Col
          lg={{ size: 5, offset: 6 }}
          md={{ size: 8, offset: 2 }}
          sm={{ size: 10, offset: 1 }}
          xl={{ size: 5, offset: 7 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade right>
            <h2>Superboost behandeling</h2>
          </Fade>
        </Col>
      </Row>
      <LetterBlock char="s" onRight />
      <Row>
        <ImgContainer
          lg={{ size: 4, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 1 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade left>
            <div>
              <img alt="Permanente make-up Instituut Visage" src={img} title="Permanente make-up Instituut Visage" />
            </div>
          </Fade>
        </ImgContainer>
        <TextContainer
          lg={{ size: 5, offset: 1 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 4, offset: 2 }}
          xs={{ size: 10, offset: 1 }}>
          <Fade bottom>
            <p>
              <strong>Dé beauty behandeling van de toekomst!</strong> Superboost is de meest effectieve en duurzame methode voor een gegarandeerde strakke, frisse huid.
            </p>
            <p>
              Ontdek onze revolutionaire en pijnloze biofeedback behandeling voor dames en heren. Zeer resultaat gericht met een prachtig natuurlijk effect! Superboost geeft jou de <strong>zachtheid van een cosmetische verzorging met de inpact van een medische behandeling.</strong>
            </p>

            <div>
              <h3>Kijk of dit iets voor jou is!</h3>
              <ul>
                <li>Je wil jouw huid zacht behandelen, zonder prikken, maar toch met resultaat?</li>
                <li>Je hebt acné en neemt liever geen medicatie of je wil je vette huid normaliseren.</li>
                <li>Je wil graag verstopte huid (lift effect) of verkrampte (frons, kraaienpootjes) spieren harmoniseren.</li>
                <li>Je wil graag een verslapte hals en kaaklijn terug verstevingen.</li>
                <li>Je wil graag een instant glow: instant hydratatie van de huid.</li>
                <li>Je hebt last van fijne rimpeltjes.</li>
                <li>Jeudigheid van de huid behouden.</li>
              </ul>
            </div>
          </Fade>
          <Button outline={false} text="Bekijk de prijzen" to="/prijslijst#superboost" />
        </TextContainer>
      </Row>
    </Wrapper>
  );
};
