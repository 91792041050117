import { FC } from "react";
import styled, { MobileFirst } from "styled";
import { Link } from "react-router-dom";
import { Close, MobileNavLink } from "components";

import icon from "images/icon/icon-white.svg";

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 0%;
  background-color: ${({ theme }): string => theme.main};
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1001;
  transition: height 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;
const BackgroundText = styled.h2`
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  color: rgba(255, 255, 255, 0.1);
  font-size: 12rem;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  z-index: 0;
`;
const LinkContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 7;
`;
const MenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  z-index: 8;
  img {
    width: 100px;
    height: auto;
    margin-bottom: 25px;
  }
  @media ${MobileFirst.ms} {
    margin: 15px;
  }
`;

export const MobileMenu: FC = () => {
  return (
    <Wrapper id="mobilenav">
      <BackgroundText>Visage</BackgroundText>
      <LinkContainer>
        <MenuItem>
          <Link to="/">
            <img
              alt="Logo Instituut Visage"
              src={icon}
              title="Logo Instituut Visage"
            />
          </Link>
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Laserontharing" to="/laserontharing" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Superboost" to="/superboost" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Afslanking" to="/afslanking" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Wellness" to="/wellness" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Ontdek" to="/ontdek" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Prijslijst" to="/prijslijst" />
        </MenuItem>
        <MenuItem>
          <MobileNavLink text="Contact" to="/contact" />
        </MenuItem>
        <MenuItem>
          <Close />
        </MenuItem>
      </LinkContainer>
    </Wrapper>
  );
};
