import { FC } from 'react';
import styled, { Size } from 'styled';
import { Container } from 'reactstrap';
import Youtube from 'react-youtube';

/* shared components */
import { TitleParts } from 'components';

/* styled components */
const Wrapper = styled.div`
  margin: 75px 0;
  padding: 75px 0;

  .line{
    width: 5%;
    height: 5px;
    background-color: ${({ theme }): string => theme.gray};
    margin-bottom: 10px;
  }
  @media ${Size.md}{
    padding: 50px 0;
    margin: 50px 0 75px 0;
  }
  @media ${Size.sm}{
    padding: 25px 0;
    margin: 25px 0 75px 0;
  }
  @media ${Size.xs}{
    padding: 0;
    margin: 25px 0 75px 0;
  }
`;
const TitlePartsSmall = styled(TitleParts)`
  @media ${Size.sm}{
    font-size: 7rem;
  }
  @media ${Size.xs}{
    font-size: 5rem;
  }
  @media ${Size.xxs}{
    font-size: 4rem;
  }
`;
const ResponsiveYoutube = styled(Youtube)`
    background-color: white;
`;
function getHeight() {
  const width = window.innerWidth;
  if (width > 1200) {
    return '624px';
  }
  if (width > 992) {
    return '526px';
  }
  if (width > 768) {
    return '390px';
  }
  if (width > 576) {
    return '287px';
  }
  return '163px';
}

export const HennaBrows: FC = () => {
  function replayVideo(event: any) {
    event.target.seekTo(0);
  }
  function videoMounted(event: any) {
    event.target.playVideo();
  }
  return (
    <Wrapper>
      <Container>
        <TitlePartsSmall isH1={false} partOne="henna" partTwo="brows" />
        <div className="line" />
        <p>
          Het is anders dan gewoon verven omdat je niet alleen de haartjes maar ook
          de huid verft. Hierdoor lijken de wenkbrauwen voller en moet je niet na een
          week weer zelf met een potlood of poeder aan de slag.
          De kleur blijft op de haartjes 5 tot 6 weken en
          5 tot 14 dagen op de huid.
        </p>
        {/* https://www.youtube.com/watch?time_continue=3&v=HpXfziWku4c */}
        <ResponsiveYoutube
          onEnd={replayVideo}
          onReady={videoMounted}
          opts={{
            height: getHeight(),
            width: '100%',
            playerVars: {
              autoplay: 1,
              mute: 1,
              loop: 1,
              controls: 0,
              showinfo: 0,
              modestbranding: 1,
              fs: 0,
              disablekb: 1,
            }
          }}
          videoId="HpXfziWku4c" />
      </Container>
    </Wrapper>
  );
};
