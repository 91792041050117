import { FC } from 'react';
import styled, { Size } from 'styled';
import { Fade } from 'react-reveal';

/* images */
import facebook from 'images/social/facebook-green.svg';
import mail from 'images/social/mail-green.svg';
import phone from 'images/social/phone-green.svg';
import placeholder from 'images/social/placeholder-green.svg';

const Wrapper = styled.nav`
  position: fixed;
  left: 40px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  z-index: 1000;
    @media ${Size.sm}{
      left: 22px;
      position: absolute;
    }
`;

const NavIcon = styled.a`
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  img{
    width: 22px;
    height: auto;
   }
  transition: transform 0.3s ease-in-out;
  &:hover{
    transform: translate(5px, 0);
  }
  @media ${Size.sm}{
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const SocialNav: FC = () => {
  return (
    <Wrapper>
      <NavIcon
        href="https://www.facebook.com/Schoonheidsinstituut-Visage-185290011570036/"
        rel="noopener noreferrer"
        target="_blank">
        <Fade delay={500} right >
          <img alt="Facebookpagina Schoonheidsinstituut Visage" src={facebook} title="Facebookpagina Schoonheidsinstituut Visage" />
        </Fade>
      </NavIcon>

      <NavIcon
        href="https://www.google.com/maps/dir/50.9506,3.28637/instituut+visage+location/@50.9756776,3.3195202,13z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x47c34031d523076f:0xbda5242f9dc6f5b6!2m2!1d3.422358!2d50.996337!3e0"
        rel="noopener noreferrer"
        target="_blank">
        <Fade delay={500} left >
          <img alt="Locatie Schoonheidsinstituut Visage" src={placeholder} title="Locatie Schoonheidsinstituut Visage" />
        </Fade>
      </NavIcon>

      <NavIcon href="mailto:saskiacomyn@skynet.be" rel="noopener noreferrer" target="_blank" >
        <Fade delay={500} right >
          <img alt="E-mailadres Schoonheidsinstituut Visage" src={mail} title="E-mailadres Schoonheidsinstituut Visage" />
        </Fade>
      </NavIcon>
      <NavIcon href="tel:051635377">
        <Fade delay={500} left>
          <img alt="Bel Schoonheidsinstituut Visage" src={phone} title="Bel Schoonheidsinstituut Visage" />
        </Fade>
      </NavIcon>
    </Wrapper>
  );
};
