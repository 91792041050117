import { FC } from 'react';
import styled, { Size } from 'styled';
import { Letter } from 'components';
import Fade from 'react-reveal/Fade';

const AbsoluteLetter = styled(Letter)`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsContainer = styled.div`
  display: flex;
  width: 80%;
  padding: 100px 0 0 0;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: stretch;

  @media ${Size.lg}{
    flex-direction: column;
    align-items: center;
  }
`;

const StepContainer = styled.div`
  position: relative;
  width: 500px;
  padding: 80px 40px;
  height: auto;
  background-color: ${({ theme }) => theme.bg};

  h3 {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.main};
  }
  p {
    font-size: 1.225rem;
  }

  p,h3 {
    position: relative;
    z-index:1;
  }

  @media ${Size.lg}{
    margin: 15px;
  }
`;

const TextContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const Steps: FC = () => {
  return (
    <StepsContainer>
      <StepContainer>
        <AbsoluteLetter char="1" isWhite />
        <TextContainer>
          <Fade left>
            <h3>Stap 1</h3>
            <p>
              Eerst worden vitamines en mineralen in de huid gebracht via een naaldloze injectie d.m.v. jetstreams, terwijl terzelfdertijd het diepere overtollige vuil uit de poriën wordt gepompt. Zo worden de actieve bestandsdelen snel en pijnloos naar de dieptste huidlaag gebracht.
            </p>
          </Fade>
        </TextContainer>
      </StepContainer>

      <StepContainer>
        <AbsoluteLetter char="2" isWhite />
        <TextContainer>
          <Fade right>
            <h3>Stap 2</h3>
            <p>
              Zeer belangrijk: de electrostimulatie op de spieren. De spier krijgt nieuwe impulsen en gaat automatisch strakker worden en gaat de huid ontspannen, ogen opentrekken en wenkbrauwen liften.
            </p>
          </Fade>
        </TextContainer>
      </StepContainer>
    </StepsContainer>
  );
};
