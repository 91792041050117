import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled, { Size } from 'styled';
import { Basic, Steps, Result } from 'views/superboost';
import { Header, MobileMenu, Footer } from 'components';

// images
import img from 'images/superboost/superboost-logo.jpg';
import icon from 'images/icon/icon-white.svg';

const Landing = styled.div`
  width: 100%;
  height: calc(100vh - 135px);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width:50%;
    height: auto;
  }
  @media ${Size.md}{
    img {
      width: 100%;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  color: white;
  padding: 100px 100px;
  margin-bottom: 125px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2, p {
      text-align: center;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  p{
    font-weight: 500;
    font-size: 1.225rem;
    margin-bottom: 40px;
  }
  &::after{
    content: '';
    position: absolute;
    right: 0;
    width: 500px;
    height: 500px;
    background-image: url(${icon});
    background-repeat: no-repeat;
    opacity: .3;
  }
`;

const StepsWrapper = styled.div`
  width: 100%;
  padding: 150px 0;

  h2 {
    font-size: 3rem;
    color: ${({ theme }): string => theme.main};
    text-align: center;
  }
`;

const CustomButton = styled.button`
  text-decoration: none;
  position: relative;
  padding: 8px 30px;
  border: 2px solid;
  border-radius: 20px;
  width: fit-content;
  border-color: white;
  font-weight: 600;
  vertical-align: middle;
  color: ${({ theme }): string => theme.main};

  a {
    font-size: 1.3rem;
    color: ${({ theme }): string => theme.main};
  }
  background-color: white;
  transition: 0.3s color ease-in-out;
  &:hover{
    color: black;
  }

`;

export const SuperBoost: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Instituut Visage | Superboost </title>
        <meta
          content="De beauty behandeling van de toekomst. Superboost is de meest effective &amp; duurzame methode voor een gegarandeerde strakke, frisse huid. Superboost geeft jou de zachtheid van een cosmetische verzorging (pijnloos) met de impact van een medische behandeling."
          name="description" />
      </Helmet>
      <MobileMenu />
      <Header />
      <Landing>
        <img alt="superboost logo" src={img} />
      </Landing>
      <Basic />
      <StepsWrapper>
        <h2>Hoe verloopt het? </h2>
        <Steps />
      </StepsWrapper>
      <Result />
      <Banner>
        <h2>Maak nu uw afspraak!</h2>
        <p>Voorafgaand is er een intakegesprek waarna we een behandeling op maat van uw huid opstellen.</p>
        <CustomButton><a href="tel:051635377">Bel nu!</a></CustomButton>
      </Banner>
      <Footer />
    </Fragment >
  );
};
