import { ICategory } from "./Categorie";

export const CategoriesData: ICategory[] = [
  {
    name: "Handverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Basis manicure", price: 25 },
          { name: "Manicure + lakken", price: 30 },
          { name: "Gelish manicure", price: 40 },
          { name: "Rubberbase manicure", price: 45 },
          { name: "Verwijderen Gelish", price: 20 },
          { name: "Polygel op de natuurlijke nagel", price: 50 },
          { name: "Polygel met verlenging", price: 65 },
          { name: "Verwijderen polygel", price: 20 },
          { name: "Aanvullend: french / decoratie", price: 5 },
        ],
      },
    ],
  },
  {
    name: "Voetverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Medische pedicure", price: 35 },
          { name: "Medische pedicure met gelish", price: 55 },
          { name: "Gelish tenen", price: 35 },
        ],
      },
    ],
  },
  {
    name: "Suikerontharing",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Wenkbrauwen", price: 10 },
          { name: "Oksels", price: 20 },
          { name: "Bovenlip of kin", price: 10 },
          { name: "Bikinilijn", price: 20 },
          { name: "Bikinilijn volledig", price: 35 },
          { name: "Onderbenen en knie", price: 30 },
          { name: "Volledige benen", price: 45 },
          { name: "Hoe meer je combineert hoe goedkoper!", price: 0 },
        ],
      },
    ],
  },
  {
    name: "Laserontharing",
    subCategories: [
      {
        name: "Gelaat",
        items: [
          { name: "Wenkbrauwen (tussenstuk)", price: 50 },
          { name: "Bovenlip", price: 55 },
          { name: "Kin", price: 60 },
          { name: "Bovenlip & kin", price: 115 },
          { name: "Wangen", price: 80 },
          { name: "Kaaklijn", price: 90 },
          { name: "Bakkebaarden", price: 80 },
          { name: "Hals", price: 100 },
          { name: "Volledig gelaat", price: 150 },
        ],
      },
      {
        name: "Lichaam",
        items: [
          { name: "Schouders", price: 150 },
          { name: "Schouders & rug", price: 295 },
          { name: "Bovenrug", price: 165 },
          { name: "Borstkas", price: 150 },
          { name: "Oksels", price: 115 }, // TODO: man moet 125 zijn
          { name: "Onderarmen", price: 125 },
          { name: "Bovenarmen", price: 125 },
          { name: "Volledige armen", price: 250 },
          { name: "Buik", price: 150 },
          { name: "Navellijn", price: 60 },
          { name: "Sliprand", price: 95 },
          { name: "Bikinilijn", price: 110 },
          { name: "Bikinilijn streepje", price: 150 },
          { name: "Volledige bikinilijn met schaamstreek", price: 195 },
          { name: "Bilnaad", price: 75 },
          { name: "Onderbenen & knie", price: 250 },
          { name: "Bovenbenen achterkant", price: 200 },
          { name: "Bovenbenen volledig", price: 220 },
          { name: "Volledige benen", price: 420 },
          { name: "Voetrug", price: 70 },
          { name: "Tenen/vingers", price: 30 },
          { name: "*Prijzen zijn per sessie", price: 0 },
        ],
      },
    ],
  },

  {
    name: "Tinten",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Wimpers kleuren", price: 12 },
          { name: "Wenkbrauwen kleuren", price: 12 },
          { name: "Wenkbrauwen epileren en kleuren", price: 20 },
          { name: "Henna brows", price: 45 },
        ],
      },
    ],
  },
  {
    name: "Gelaatsverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Make-up", price: 45 },
          { name: "Hydra 3HA", price: 78 },
          { name: "Jeunesse", price: 88 },
          { name: "Energie", price: 95 },
        ],
      },
    ],
  },
  {
    name: "Superboost",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Gelaat", price: 170 },
          { name: "Gelaat en hals", price: 270 },
          { name: "Hals en décolleté", price: 270 },
          { name: "Acné behandeling", price: 80 },
          {
            name: "Kuur vanaf 5 behandelingen + 1 gratis (prijs per behandeling)",
            price: 170,
          },
        ],
      },
    ],
  },
  {
    name: "Lichaamsverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Lichaamspeeling", price: 40 },
          { name: "Mini-stone therapie", price: 65 },
          { name: "Hot-stone therapie", price: 75 },
          { name: "Spa-therapie", price: 120 },
          { name: "Anti-age-therapie", price: 140 },
          { name: "Special men-therapie", price: 120 },
          { name: "Supplement hoofdhuidmassage-voetmassage", price: 20 },
        ],
      },
    ],
  },
  {
    name: "Arrangementen",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Huwelijksarrangement", price: 150 },
          { name: "Ook andere arrangementen zijn mogelijk!", price: 0 },
        ],
      },
    ],
  },
];
