import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
`;

interface FlexProps {
  align: string;
  direction: string;
  justify: string;
}

export const Flex: FC<FlexProps> = (props) => {
  return (
    <Wrapper {...props} />
  );
};
