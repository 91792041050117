import { FC } from 'react';
import styled from 'styled';

export const Container = styled.div`
  display: block;
  background-color: ${({ theme }): string => theme.main};
  color: white;
  border-radius: 1rem;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
`;

interface TagProps {
  text: string;
};
export const Tag: FC<TagProps> = ({ text }) => {
  return (
    <Container><p>{text}</p></Container>
  );
};
