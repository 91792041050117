import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import styled, { Size } from 'styled';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import { Header, MobileMenu, Footer, Title } from 'components';
import { Map } from 'views/contact/Map';

/* images */
import placeholder from 'images/social/placeholder.svg';
import phone from 'images/social/phone.svg';
import mail from 'images/social/mail.svg';
import facebook from 'images/social/facebook.svg';

const ContentContainer = styled(Container)`
  position: relative;
  margin-top: 150px;
  margin-bottom: 150px;
  min-height: 100vh;

  @media ${Size.sm}{
    padding-bottom: 200px;
    margin-top: 150px;
  }
  @media ${Size.xs}{
    margin-bottom: 100px;
    margin-top: 50px;
    padding-bottom: 200px;
    padding-top: 25px;
  }
`;

const AbsoluteBgContainer = withReveal(styled.div`
  position: absolute;
  top: 15%;
  right: 0%;
  width: 800px;
  height: 700px;
  background-color: ${({ theme }): string => theme.bg};
  z-index: -1;

  span{
    position: absolute;
    bottom: -25%;
    right: 15%;
    color: white;
    font-size: 20rem;
    font-weight: 500;
  }
  @media ${Size.md}{
    width: 100%;
  }
  @media ${Size.sm}{
    height: 850px;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  @media ${Size.xs}{
    height: 850px;
  }
`, <Fade bottom />);

const TitleContainer = styled(Col)`
    text-align: left;
    margin-bottom: 25px;
    padding-bottom: 25px;
  @media ${Size.lg}{
    text-align: center;
    padding-bottom: 0;
    margin-left: 25px;
  }
  @media ${Size.sm}{
    margin-left: auto;
    margin-right: auto;
  }
`;

const InfoContainer = styled.ul`
  width: 100%;
  margin-bottom: 50px;
  ul{
    margin-left: 0;
    padding-left: 0;
  }
`;
const OpeningsurenContainer = styled(Col)`
  ul{
    margin-left: 0;
    padding-left: 0;
  }
  li{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    .hours{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    p{
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
  }
  .makeup{
    margin-top: 25px;
  }

  @media ${Size.xxs}{
    h2{
      text-align: center;
    }
    li{
      .hours{
        flex-direction: column;
        p{
          margin-top: 0;
          margin-bottom: 0;
          line-height: 1;
        }
        .divider{
          display: none;
        }
      }
    }
  }
`;
const Item = withReveal(styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-start;
  margin-top: 25px;
  margin-bottom: 25px;
  @media ${Size.xs}{
    flex-direction: column;
  }
  @media ${Size.xxs}{
    text-align: center;
  }
`, <Fade cascade top />);

const Icon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 50px;
  @media ${Size.xs}{
    margin-right: 0;
    margin-bottom: 5px;
  }
  @media ${Size.xxs}{
   display: block;
   margin: 10px 15px;
  }
`;
const Strong = styled.strong`
  font-weight:600;
`;
const StyledFooter = styled(Footer)`
  margin-top: 100px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  background-color: white;
  
  @media ${Size.sm}{
    height: 350px;
  }
`;
export const Contact: FC = () => {
  return (
    <>
      <Helmet>
        <title>Instituut Visage | Contact</title>
        <meta content="Heb je nog vragen of wil je jouw verwenmoment vastleggen? Neem dan gerust contact op met ons. Schoonheidsinstituut Visage, Vrouwenstraat 9 8700 Aarsele, 051 63 53 77" name="description" />
      </Helmet>
      <MobileMenu />
      <Header />
      <main>
        <MapContainer>
          <Map />
        </MapContainer>
        <ContentContainer>
          <AbsoluteBgContainer>
            <span>O</span>
          </AbsoluteBgContainer>
          <Row>
            <TitleContainer>
              <Title isH1 text="contact" />
            </TitleContainer>
          </Row>
          <Row>
            <Col
              lg={{ size: 11, offset: 1 }}
              md={{ size: 11, offset: 1 }}
              xl={{ size: 10, offset: 2 }}
              xs={{ size: 10, offset: 1 }}>
              <InfoContainer>
                <Item>
                  <Icon alt="Locatie Instituut Visage" src={placeholder} title="Locatie Instituut Visage" />
                  Vrouwenstraat 9 ,8700 Aarsele
                </Item>
                <Item>
                  <Icon alt="Gsm Instituut Visage" src={phone} title="locatie instituut visage" />
                  051 63 53 77
                </Item>
                <Item>
                  <Icon alt="Mail Instituut Visage" src={mail} title="Mail Instituut Visage" />
                  saskiacomyn@skynet.be
                </Item>
                <Item>
                  <Icon alt="Facebook pagina Instituut Visage" src={facebook} title="Facebook pagina Instituut Visage" />
                  Schoonheidsinstituut Visage
                </Item>
              </InfoContainer>
            </Col>
            <OpeningsurenContainer
              lg={{ size: 6, offset: 1 }}
              md={{ size: 6, offset: 1 }}
              sm={{ size: 9, offset: 1 }}
              xl={{ size: 5, offset: 2 }}
              xs={{ size: 10, offset: 1 }}>
              <h2>Openingsuren</h2>
              <ul>
                <li>
                  <Strong>Maandag</Strong>
                  <p> Gesloten</p>
                </li>
                <li>
                  <Strong>Dinsdag - Vrijdag</Strong>
                  <div className="hours">
                    <p>9u - 12u</p>
                    <div className="divider">&nbsp; | &nbsp;</div>
                    <p>13u - 19u</p>
                  </div>
                </li>
                <li>
                  <Strong>Zaterdag</Strong>
                  <p>9u - 12u</p>
                </li>
              </ul>
            </OpeningsurenContainer>
          </Row>
        </ContentContainer>
      </main>
      <StyledFooter />
    </>
  );
};
