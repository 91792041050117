import { FC } from 'react';
import styled, { Size } from 'styled';
import { css } from 'styled-components';

/* styled components */
const sharedStyle = css<{ center: boolean }>`
  color: ${({ theme }): string => theme.main};
  font-size: 9rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: ${({ center }) => center ? 'center' : 'left'};

  @media ${Size.lg} {
    font-size: 8rem;
  }
  @media ${Size.md} {
    font-size: 5rem;
  }
  @media ${Size.sm} {
    font-size: 3.5rem;
  }
  @media ${Size.xs}{
    font-size: 2.8rem;
  }
`;
const HeaderOne = styled.h1`${sharedStyle};`;
const HeaderTwo = styled.h2`${sharedStyle}`;

interface TitleProps {
  isH1?: boolean;
  text: string;
  center?: boolean;
}

export const Title: FC<TitleProps> = ({ isH1 = false, text, center = false }) => {
  return (
    isH1 ?
      <HeaderOne center={center}>{text}</HeaderOne>
      :
      <HeaderTwo center={center}>{text}</HeaderTwo>
  );
};
