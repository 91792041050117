import { FC, useContext } from "react";
import styled, { Size } from "styled";
import { Row, Col } from "reactstrap";
import { ThemeContext } from "styled-components";
import { Header, MobileMenu } from "components";

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
`;
const ContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 135px);
  align-items: center;
`;
const ImgContainer = styled(Col)`
  position: relative;
  height: 65vh;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${(props) => props.imgposition};
  padding: 0;
  h1 {
    position: absolute;
    top: 50%;
    margin: 0;
    left: -60%;
    transform: translate(0, -50%);
    font-size: 8rem;
    color: ${(props) => props.color};
  }
  @media ${Size.lg} {
    h1 {
      font-size: 4rem;
      left: -40%;
    }
    @media ${Size.md} {
      h1 {
        font-size: 3rem;
        left: -30%;
      }
    }
    @media ${Size.xs} {
      h1 {
        right: 0;
        left: 0;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2rem;
      }
    }
  }
`;
const FullRow = styled(Row)`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
`;

interface LandingProps {
  title: string;
  img: string;
  imgPosition: string;
  color?: string;
}

export const Landing: FC<LandingProps> = ({
  title,
  img,
  imgPosition,
  color,
}) => {
  const theme = useContext(ThemeContext);
  if (!color) {
    color = theme.main;
  }

  return (
    <Wrapper>
      <MobileMenu />
      <Header />
      <ContentContainer>
        <FullRow>
          <ImgContainer
            color={color}
            img={img}
            imgposition={imgPosition}
            lg={{ size: 7, offset: 4 }}
            md={{ size: 7, offset: 4 }}
            sm={{ size: 7, offset: 4 }}
            xl={{ size: 5, offset: 6 }}
            xs={{ size: 12, offset: 0 }}
          >
            <h1>{title}</h1>
          </ImgContainer>
        </FullRow>
      </ContentContainer>
    </Wrapper>
  );
};
