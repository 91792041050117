import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
import { StepBlock } from './StepBlock';

/* images */
import applewoman from 'images/afslanking/applewoman.png';

/* styled components */
const Wrapper = styled.div`
  position: relative;
  padding: 100px 0;
  margin: 100px 0;
  @media ${Size.md}{
    padding: 75px 0;
  }
  @media ${Size.sm}{
    margin: 75px 0;
  }
  @media ${Size.xs}{
    margin: 50px 0;
  }
`;
const ImageContainer = styled(Col)`
  background-image: url(${applewoman});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 450px;
  @media ${Size.xs}{
    min-height: 350px;
  }
  @media ${Size.xxs}{
    min-height: 250px;
  }
`;
const TextContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p{ width: 65%; }
  @media ${Size.lg}{
    p{ width: 75%; }
  }
  @media ${Size.md}{
    text-align: center;
    p{ width: 100% }
  }
`;
const BgBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: ${({ theme }) => theme.bg};
  z-index: -1;
`;

export const StepOne: FC = () => {
  return (
    <Wrapper>
      <StepBlock side="left" value="1" />
      <Row>
        <ImageContainer lg="5" md="8" sm="8" xl="4" xs="10" />
        <TextContainer
          lg={{ size: 5, offset: 2 }}
          md={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 6, offset: 2 }}
          xs={{ size: 10, offset: 1 }}>
          <h3 className="step">
            Ontgiftingsfase
          </h3>
          <p>
            De eerste fase is een goedmaakfase en de iets striktere fase waarin we je
            lichaam rust gaan geven. In deze fase gaan we ontgiften, en start de vetvermindering
            op de juiste plaatsen! Deze fase duurt gemiddeld 3 weken.
          </p>
        </TextContainer>
      </Row>
      <BgBox />
    </Wrapper>
  );
};
