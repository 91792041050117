import { FC } from 'react';
import styled, { Size } from 'styled';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';

/* styled components */
const Wrapper = styled.div<{ onRight: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  width: 300px;
  height: 150px;
  background-color: ${({ theme }) => theme.bg};
  right: ${({ onRight }) => onRight ? 0 : null};
  left: ${({ onRight }) => onRight ? null : 0};
  z-index: -1;
  top: 0;
  @media ${Size.lg}{
    width: 200px;
    height: 100px;
  }
  @media ${Size.xs}{
    top: -75px;
  }
`;
const Letter = withReveal(styled.span`
  font-size: 10rem;
  font-weight: 600;
  color: white;
  line-height: 1;
  @media ${Size.lg}{
    font-size: 6rem;
  }
`, <Fade bottom />);

interface LetterBlockProps {
  char: string;
  onRight: boolean;
}

export const LetterBlock: FC<LetterBlockProps> = ({ char, onRight }) => {
  return (
    <Wrapper onRight={onRight}>
      <Letter>{char}</Letter>
    </Wrapper>
  );
};
