import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const DEFAULT = {
  main: '#80b6b0',
  gray: '#4d4d4f',
  bg: '#f8f8f8'
};

export type ThemeType = typeof DEFAULT;
export const styled = baseStyled as ThemedStyledInterface<ThemeType>;
