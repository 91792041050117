import { FC } from 'react';
import styled, { Size } from 'styled';
import { Container, Row, Col } from 'reactstrap';

/* images */
import img from 'images/huwelijksarrangement.jpg';
import { Letter } from '../../components/Letter';

const Wrapper = styled.div`
  margin: 150px 0 250px 0;

  @media ${Size.xs}{
    margin: 0 0 250px 0;
  }
`;
const ImageContainer = styled(Col)`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${Size.md}{
    height: 400px;
  }
`;
const TextContainer = styled(Col)`
  position: relative;
  padding: 160px 40px;
  background-color: ${({ theme }): string => theme.bg};
  p{
    margin: 15px 0;
  }
  @media ${Size.sm}{
      padding: 100px 40px;
  }
  @media ${Size.xs}{
    padding: 100px 30px;
  }
  @media ${Size.xxs}{
    padding: 50px 15px 75px 15px;
  }
`;
const AbsoluteLetter = styled(Letter)`
  position: absolute;
  bottom: -12%;
  right: 0;

  @media ${Size.lg}{
    bottom: -6%;
  }
  @media ${Size.md}{
    bottom: -2%;
    right: 15%;
    font-size: 10rem;
  }
`;

export const Huwelijk: FC = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <ImageContainer
            lg={{ size: 4, offset: 1 }}
            md={{ size: 10, offset: 1 }}
            sm={{ size: 10, offset: 1 }}
            xl={{ size: 4, offset: 1 }}
            xs={{ size: 10, offset: 1 }} />
          <TextContainer
            lg={{ size: 6, offset: 0 }}
            md={{ size: 10, offset: 1 }}
            sm={{ size: 10, offset: 1 }}
            xl={{ size: 6, offset: 0 }}
            xs={{ size: 10, offset: 1 }}>
            <h2>
              Huwelijksarrangement
            </h2>
            <p>
              Een week voor het huwelijk.. een gelaatsverzoring met bijhorende ontharingen,
              een voetverzoring met gelish, kleur of french. De week van het huwelijk.. Een
              gelish manicure. De dag van het huwelijk.. Een bruidsmaquillage gratis!
            </p>
            <p>
              Dit arrangement is ook geldig voor familieleden van de bruid of bruidegom. Ook
              andere samenstellingen zijn mogelijk.
            </p>
            <AbsoluteLetter char="h" isWhite />
          </TextContainer>
        </Row>
      </Container>
    </Wrapper>
  );
};
