import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Helmet } from 'react-helmet';
/* images */
import img from 'images/ontdek.jpg';
import { Landing, Footer, } from 'components';
import { SuikerOntharing, HennaBrows, PermanenteMakeUp } from 'views/ontdek';

export const Ontdek: FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>Instituut Visage | Ontdek</title>
        <meta content="Bij schoonheidssalon Visage zijn alle behandelingen up-to-date door regelmatige bijscholing. Blijf hier op de hoogte van alle nieuwste behandelingen, promoties en acties." name="description" />
      </Helmet>
      <Landing
        color={theme.main}
        img={img}
        imgPosition="center" title="Ontdek"
      />
      <SuikerOntharing />

      <PermanenteMakeUp/>
      <HennaBrows />
      <Footer />
    </>
  );
};
