import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';

/* images */
import applegreen from 'images/afslanking/applegreen.png';
/* shared components */
import { StepBlock } from './StepBlock';

/* styled components */
const Wrapper = styled.div`
  position: relative;
  padding: 75px 0 0 0;
  margin: 100px 0;

  @media ${Size.sm}{
    margin: 75px 0 75px 0;
  }
`;
const TextContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  p {
    width: 65%;
  }
  @media ${Size.lg}{
    p{ width: 75%; }
  }
  @media ${Size.sm}{
    text-align: center;
    p{ width: 100% }
  }
`;
const ImgContainer = styled(Col)`
  background-image: url(${applegreen});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 450px;
  @media ${Size.sm}{
    min-height: 300px;
  }
  @media ${Size.xs}{
    min-height: 250px;
  }
`;
const BgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background-color: ${({ theme }) => theme.bg};
  z-index: -1;
`;

export const StepTwo: FC = () => {
  return (
    <Wrapper>
      <StepBlock side="right" value="2" />
      <Row>
        <TextContainer
          lg={{ size: 6, offset: 2 }}
          md={{ size: 6, offset: 2 }}
          sm={{ size: 8, offset: 2 }}
          xl={{ size: 6, offset: 2 }}
          xs={{ size: 10, offset: 1 }}>
          <h3 className="step">
            Stabiliseren
          </h3>
          <p>
            In de zoekfase ga je nog steeds afslanken maar gaan we ook ontdekken welke
            ingrediënten eventueel een probleem voor je vormen en zo leren je gewicht te
            behouden terwijl je ook kan genieten.
          </p>
        </TextContainer>
        <ImgContainer lg="4" md="4" sm={{ size: 4, offset: 8 }} xl="4" xs={{ size: 6, offset: 6 }} />
      </Row>
      <BgBox />
    </Wrapper>
  );
};
