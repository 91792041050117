import { FC } from 'react';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';

/* shared components */
import { Header, MobileMenu, Title } from 'components';

/* styled components */
const Wrapper = styled.div`
  width: 100%;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FullRow = styled(Row)`
  width: 100%;
`;
const TitleContainer = styled(Col)`
  margin-top: 100px;
  margin-bottom: 100px;
  h1{
    font-size: 8rem;
  }
  @media ${Size.lg}{
    h1{font-size: 6rem}
  }
  @media ${Size.md}{
    h1{font-size: 4rem}
  }
  @media ${Size.sm}{
    margin-bottom: 50px;
    h1{
      font-size: 3rem;
      text-align: center;
    }
  }
  @media ${Size.xs}{
    font-size: 25rem;
    h1{
      font-size: 2.5rem
    }
  }
`;

const Line = styled.div`
  width: 5%;
  height: 5px;
  background-color: ${({ theme }): string => theme.gray};
  margin-left: 25px;

  @media ${Size.sm}{
    margin: 0 auto;
  }
`;

export const Landing: FC = () => {
  return (
    <Wrapper>
      <MobileMenu />
      <Header />
      <ContentContainer>
        <FullRow>
          <TitleContainer
            lg={{ size: 10, offset: 2 }}
            md={{ size: 10, offset: 2 }}
            sm={{ size: 12, offset: 0 }}
            xl={{ size: 10, offset: 2 }}>
            <Title isH1 text="ontdek" />
            <Line />
          </TitleContainer>
        </FullRow>
      </ContentContainer>
    </Wrapper>
  );
};
